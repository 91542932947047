<template>
  <c-dialog
    ref="dialogTeaching"
    :visible="showTeachingDialog"
    :optionsData="optionsData"
    @submit-form="submitTeachingForm"
    @close-dialog="closeTeachingDialog"
    class="teaching-dialog"
  >
    <div
      slot="customView"
      v-loading="dialogLoading"
      class="flex custom-content"
    >
      <div class="left-content">
        <el-scrollbar ref="scrollDivLeft" class="scrollbar-left">
          <div
            v-if="classList.length > 0"
            class="custom-content-title"
            style="margin-bottom: 12px"
          >
            {{ $t("el.courseClassManage.chooseClass") }}
          </div>
          <!-- 选择班级区域 start -->
          <div
            v-if="classList.length > 0"
            class="flex flex-wrap"
            style="padding-bottom: 6px"
          >
            <div
              v-for="(item, index) in classList"
              :key="item.id"
              :class="[item.isChosed ? 'btn-class-chose' : 'btn-class-unchose']"
              style="margin-right: 20px; margin-bottom: 14px"
              @click="handleClassBtn(item, index)"
            >
              {{ item.gradeName + item.className }}
            </div>
          </div>
          <!-- 选择班级区域 end -->
          <div class="custom-content-title" style="margin-bottom: 10px">
            {{ $t("el.common.chooseKnowledge") }}
          </div>
          <!-- 知识点区域 start -->
          <div v-if="knowledgeList.length > 0">
            <div
              v-for="(item, index) in knowledgeList"
              class="details-chapter"
              :key="item.id"
            >
              <div
                class="flex flex-m details-chapter-header"
                @click="handleChapterClick(item, index)"
              >
                <el-image
                  :class="
                    item.isRetract
                      ? 'details-chapter-icon'
                      : 'details-chapter-icon2'
                  "
                  :src="item.isRetract ? iconArrowRight : iconArrowDown"
                  fit="fit"
                ></el-image>
                <div class="details-chapter-title">
                  <div
                    style="
                      margin-bottom: -5px;
                      padding-left: 2px;
                      padding-right: 2px;
                    "
                  >
                    U{{ index + 1 }}
                  </div>
                  <div class="u-line"></div>
                </div>
                <span class="details-chapter-title single-line">
                  <tooltip-over
                    :content="item.knowledgeName"
                    :singleAble="true"
                  ></tooltip-over>
                </span>
              </div>
              <!-- 知识点展开列表 start-->
              <div v-show="!item.isRetract">
                <div
                  v-for="(obj, idx) in item.children"
                  :key="obj.id"
                  @click="handleKnowledgeClick(index, idx, obj)"
                >
                  <div
                    :class="[
                      'flex',
                      'flex-m',
                      obj.isChosed
                        ? 'details-section-chose'
                        : 'details-section',
                      obj.hasResource ? 'details-section-limit' : '',
                    ]"
                  >
                    <span
                      :style="
                        obj.isTeacherTrain && obj.isTeachin
                          ? 'max-width: 120px;'
                          : ''
                      "
                    >
                      <tooltip-over
                        :content="idx + 1 + '、' + obj.knowledgeName"
                        :singleAble="true"
                      ></tooltip-over>
                    </span>
                    <div class="flex flex-m details-section-content">
                      <el-image
                        class="details-section-lock"
                        v-if="isTryCourse && obj.idx > 2"
                        :src="iconLock"
                      ></el-image>
                      <span
                        v-if="obj.isTeacherTrain"
                        class="details-section-instructions"
                        >{{ $t("el.classroomWps.teacherInstructions") }}</span
                      >
                      <el-tooltip
                        v-if="obj.isTeaching"
                        effect="dark"
                        placement="top"
                        :enterable="true"
                      >
                        <div slot="content">
                          <div
                            style="text-align: right; padding: 2px 0"
                            class="flex"
                            v-for="o in obj.teachList"
                            :key="o.staffName"
                          >
                            <div
                              style="
                                width: fit-content;
                                magin-left: 0;
                                margin-right: auto;
                              "
                            >
                              {{ o.staffName }}
                            </div>
                            <span style="margin-left: 10px">{{
                              o.startTime
                            }}</span>
                          </div>
                        </div>
                        <!-- <div slot="content">多行信息<br />第二行信息</div> -->
                        <!-- <div slot="content">
                      <div
                        v-for="element in teachList"
                        :key="element.staffName"
                      >
                        {{ element.staffName + (<br />) + element.startTime }}
                      </div>
                    </div> -->
                        <div class="flex details-section-btn">
                          <div class="details-section-btn-sk">
                            {{ $t("el.common.learned") }}
                          </div>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 知识点展开列表 end-->
              <div
                v-if="index !== knowledgeList.length - 1"
                class="dash-line"
              ></div>
            </div>
          </div>
          <!-- 知识点为空 start -->
          <div
            v-else-if="knowledgeList.length === 0"
            style="text-align: center; padding-top: 40px"
          >
            <img
              class="no-img"
              :src="iconEmpty"
              :alt="$t('el.courseDetails.centerName')"
              :title="$t('el.courseDetails.centerName')"
            />
            <div class="no-text">{{ $t("el.common.noData") }}</div>
          </div>
          <!-- 知识点为空 end -->
          <!-- 知识点区域 end -->
        </el-scrollbar>
      </div>
      <div class="custom-content-line"></div>
      <div class="right-content">
        <!-- 选择课件区域 start -->
        <div class="custom-content-title" style="margin-bottom: 12px">
          {{ $t("el.teaching.selectCourse") }}
        </div>
        <el-scrollbar
          ref="scrollDiv"
          class="el-scrollbar_x"
          style="height: calc(100% - 25px)"
        >
          <div
            v-show="resourcesList.length > 0"
            class="course-content flex flex-wrap"
          >
            <div
              v-for="(item, index) in resourcesList"
              :class="item.isChosed ? 'resource-item-chosed' : 'resource-item'"
              :key="item.id"
              @click="handleResourceClick(item, index)"
            >
              <div class="flex flex-m">
                <el-image
                  class="resource-item-img"
                  :src="getWpsType(item.format) === 'ppt' ? iconPPT : iconPDF"
                  fit="fit"
                ></el-image>
                <!-- <div
                  v-if="
                    ~~item.categoryPlatform || ~~item.categoryPlatform === 0
                  "
                  class="resource-item-mine"
                >
                  {{
                    ~~item.categoryPlatform === 0
                      ? $t("el.teaching.mine")
                      : ~~item.categoryPlatform === 1
                      ? $t("el.planCourse.school")
                      : ~~item.categoryPlatform === 2
                      ? $t("el.common.Group")
                      : ""
                  }}
                </div> -->
                <div class="resource-item-name">
                  <tooltip-over
                    :content="item.resourceName"
                    :singleAble="true"
                  ></tooltip-over>
                </div>
                <!-- <div
                  :class="getCacheClass(item)"
                  @click.stop="onHandleCache(item)"
                >
                  {{ getCacheText(item) }}
                </div> -->
              </div>

              <div class="resource-item-line"></div>
              <el-image
                class="resource-item-mine"
                :src="getIconSrc(item)"
                fit="fit"
              ></el-image>
            </div>
          </div>
          <div
            class="empty-content"
            v-show="!knowledgeId || resourcesList.length === 0"
          >
            <el-image
              class="resource-empty"
              :src="iconEmpty"
              fit="fit"
            ></el-image>
            <div class="empty-content-text">
              {{
                knowledgeId
                  ? $t("el.common.noResources")
                  : $t("el.common.knowledgeFirst")
              }}
            </div>
          </div>
        </el-scrollbar>
        <!-- 选择课件区域 end -->
      </div>
      <cache-w-p-s-file
        :showPreviewDialog="showPreviewDialog"
        :fileType="fileType"
        :file="currentFile"
        @close-dialog="closePreviewDialog"
      ></cache-w-p-s-file>
      <!-- 提示确定退出对照 -->
      <el-dialog
        width="464px"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :append-to-body="true"
        :visible.sync="exitVisible"
      >
        <div slot="title" class="el-dialog_title red">
          {{ $t("el.teaching.confirmExit") }}
        </div>
        <div class="el-dialog__content">
          {{ "当前有课件在缓存，关闭后缓存任务将暂停，确认关闭？" }}
        </div>
        <span slot="footer">
          <el-button @click="exitVisible = false">{{
            $t("el.common.cancel")
          }}</el-button>
          <el-button type="primary" @click="handleExitCache">{{
            $t("el.common.confirm")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </c-dialog>
</template>

<script>
import CDialog from "@/components/biz-components/CDialog/index.vue";
import { getQueryResourceAndClass } from "@/api/classroom/bk-kj";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import {
  getCourseDetail,
  getCourseClassList,
  checkAvailability,
} from "@/api/courseList/index";
import { handparams } from "../../utils";
import CacheWPSFile from "@/components/biz-components/CacheWPSFile/index.vue";
/**
 * 两个值字符串是否相等
 * @param {*} str1
 * @param {*} str2
 */
function eqString(str1, str2) {
  return str1 + "" === str2 + "";
}
export default {
  data() {
    return {
      showPreviewDialog: false, // 预览其他文件
      fileType: "", // 文件类型
      currentFile: {},
      playUrl: "", // 播放的url

      showTeachingDialog: false, // 授课弹框
      optionsData: {}, // 弹框默认数据
      canCommit: true, // 是否可提交表单
      classList: [], // 班级列表
      resourcesList: [], // 课件列表
      knowledgeList: [], // 知识点列表
      dialogLoading: false,
      iconArrowDown: require("@/assets/images/icon/icon-arrow-down.png"),
      iconArrowRight: require("@/assets/images/icon/icon-arrow-right.png"),
      iconPPT: require("@/assets/images/icon/icon-ppt.png"),
      iconPDF: require("@/assets/images/icon/icon-pdf.png"),
      iconEmpty: require("@/assets/images/icon-empty-resource.png"),
      iconLock: require("@/assets/images/icon/icon-lock.png"),
      mineIcon: require("@/assets/images/icon/icon-mine.png"),
      schoolIcon: require("@/assets/images/icon/icon-school-teach.png"),
      groupIcon: require("@/assets/images/icon/icon-group.png"),
      knowledgeId: null, // 知识点id
      resourcesItem: {}, // 课件id
      classId: null, // 班级id
      classType: null, // 班级类型
      gradeId: null, // 年级 id
      className: "", // 班级名字
      schoolCode: "",
      isTryCourse: true,
      cacheTaskList: [], // 缓存任务列表
      isCaching: false, // 当前是否正在缓存
      exitVisible: false, // 二次确认弹窗

      id: "",
      originTenantId: "",
      originTenantType: "",
      isPending: false,
    };
  },
  inject: ["reactiveMsg"],
  props: {
    // knowledgeList: {
    //   type: Array,
    //   default: () => [],
    // },
    // 默认的课件
    defaultFile: {
      type: Object,
      default: () => {},
    },
    // 是否是试用课程
    isTry: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    computedProperty() {
      return this.reactiveMsg();
    },
  },
  components: {
    CDialog,
    tooltipOver,
    CacheWPSFile,
  },
  created() {
    this.isTryCourse = this.isTry;
  },
  methods: {
    /**
     * 初始化缓存监听
     */
    initListener() {
      // 监听登录消息
      if (typeof window.addEventListener !== "undefined") {
        window.addEventListener("message", this.handleMessage, false);
      } else if (typeof window.attachEvent !== "undefined") {
        window.attachEvent("onmessage", this.handleMessage);
      }
    },
    /**
     * 移除缓存监听
     */
    removeListener() {
      window.removeEventListener("message", this.iframeInitPageAction);
      if (typeof window.removeEventListener !== "undefined") {
        window.removeEventListener("message", this.handleMessage);
        window.removeEventListener("message", this.iframeLoad);
      } else if (typeof window.detachEvent !== "undefined") {
        window.detachEvent("onmessage", this.handleMessage);
        window.detachEvent("onmessage", this.iframeLoad);
      }
    },
    /**
     * 监听来自缓存wps的消息
     * @param {*} message
     */
    handleMessage(message) {
      try {
        let data = message.data && JSON.parse(message.data);
        //   console.log("handleMessage====", event);
        //   console.log("data====", data);

        if (
          data.type === "AIYKT" &&
          data.data &&
          data.data.type === "CACHE_STATUS"
        ) {
          if (data.data && data.data.resourceId) {
            let resourceIdList = JSON.parse(
              localStorage.getItem("resourceIdList")
            );
            console.log("resourceIdList===", resourceIdList);

            console.log(
              "resourceIdList instanceof Array===",
              Object.prototype.toString.call(resourceIdList)
            );
            // 如果不是数组  置空
            if (
              Object.prototype.toString.call(resourceIdList) !==
              "[object Array]"
            ) {
              resourceIdList = [];
            }
            if (resourceIdList) {
              if (resourceIdList.indexOf(data.data.resourceId) === -1) {
                resourceIdList.push(data.data.resourceId);
                let index = this.resourcesList.findIndex(
                  (item) => String(item.resourceId) === data.data.resourceId
                );
                if (index !== -1) {
                  this.resourcesList[index].cacheStatus = 4;
                  this.$set(
                    this.resourcesList,
                    index,
                    this.resourcesList[index]
                  );
                }
              }
            } else {
              resourceIdList = [data.data.resourceId];
            }
            localStorage.setItem(
              "resourceIdList",
              JSON.stringify(resourceIdList)
            );
          }
          this.executeCacheTask(data.data.resourceId);
        }
      } catch (error) {
        // ...
      }
    },
    // 执行 缓存任务
    executeCacheTask(resourceId) {
      if (this.showPreviewDialog) {
        this.closePreviewDialog();
        this.removeListener(); // 移除监听
        let index = this.cacheTaskList.findIndex(
          (item) => ~~item.resourceId === ~~resourceId
        );
        if (index !== -1) {
          this.cacheTaskList.splice(index, 1);
        }

        if (this.cacheTaskList.length > 0) {
          this.$nextTick(() => {
            this.openCacheIframe(this.cacheTaskList[0]);
          });
        } else {
          this.isCaching = false;
        }
      }
    },
    // 开启 iframe 缓存
    openCacheIframe(item) {
      this.resourcesItem = item;

      this.fileType = "wpsFile";
      this.currentFile = item;
      this.currentFile.courseId = this.id;
      this.showPreviewDialog = true;
      this.isCaching = true;
      this.initListener(); // 缓存监听
    },
    // 获取文件归属 icon
    getIconSrc(item) {
      let icon = "";
      switch (item.categoryPlatform) {
        case 0:
          icon = this.mineIcon;
          break;
        case 1:
          icon = this.schoolIcon;
          break;
        case 2:
          icon = this.groupIcon;
          break;

        default:
          break;
      }
      return icon;
    },
    // 根据缓存返回 样式
    getCacheClass(item) {
      if (item.cacheStatus === 1 || item.cacheStatus === 3) {
        return "resource-item-cache";
      } else if (item.cacheStatus === 2) {
        return "resource-item-caching";
      } else {
        return "resource-item-cached";
      }
    },
    // 点击缓存按钮
    onHandleCache(item) {
      if (item.cacheStatus === 1) {
        // 如果缓存任务列表中不存在，添加缓存任务
        if (this.cacheTaskList.indexOf(item) === -1) {
          this.cacheTaskList.push(item);
          console.log("this.cacheTaskList===", this.cacheTaskList);
          if (!this.isCaching) {
            // 如果没在缓存，开启缓存 iframe
            this.openCacheIframe(item);
          }
        }
        console.log("this.showPreviewDialog", this.showPreviewDialog);
        this.$set(item, "cacheStatus", 2);
      } else if (item.cacheStatus === 2) {
        // this.closePreviewDialog();
        // this.$set(item, "cacheStatus", 1);
        // this.removeListener(); // 取消缓存监听
        this.cancelCacheTask(item);
      } else if (item.cacheStatus === 3) {
        return "已暂停";
      } else {
        return "已缓存";
      }
    },
    // 取消缓存任务
    cancelCacheTask(item) {
      // 如果正在缓存，判断是否是当前缓存任务，如果是，取消当前任务，进入下一个任务，如果不是，直接删除任务，更改状态
      if (this.isCaching) {
        if (~~item.resourceId === ~~this.currentFile.resourceId) {
          this.executeCacheTask(item.resourceId);
          this.$set(item, "cacheStatus", 1);
        } else {
          let index = this.cacheTaskList.findIndex(
            (obj) => ~~obj.resourceId === ~~item.resourceId
          );
          if (index !== -1) {
            this.cacheTaskList.splice(index, 1);
          }

          this.$set(item, "cacheStatus", 1);
          !this.isCaching && this.removeListener(); // 没在缓存时移除监听
        }
      }
    },
    // 根据缓存返回
    getCacheText(item) {
      if (item.cacheStatus === 1) {
        return "缓存";
      } else if (item.cacheStatus === 2) {
        return "取消缓存";
      } else if (item.cacheStatus === 3) {
        return "已暂停";
      } else {
        return "已缓存";
      }
    },
    // 关闭预览弹框
    closePreviewDialog() {
      this.showPreviewDialog = false;
    },
    // 弹框
    openTeachingDialog() {
      this.optionsData = {
        dialogTitle: this.$t("el.courseDetails.teachingLessons"),
        "show-close": false,
      };
      this.canCommit = true;
      this.showTeachingDialog = true;
      this.id = this.computedProperty.courseId;
      this.originTenantId = this.computedProperty.originTenantId;
      this.originTenantType = this.computedProperty.originTenantType;
      this.courseTenantInfoId = this.computedProperty.courseTenantInfoId;
      this.getCourseClassList(
        {
          courseId: this.id,
          originTenantId: this.originTenantId,
          originTenantType: this.originTenantType,
          courseTenantInfoId: this.courseTenantInfoId,
        },
        true
      );
    },
    // 点击一级知识点
    handleChapterClick(item, index) {
      this.$set(item, "isRetract", !item.isRetract);
    },
    // 点击知识点
    handleKnowledgeClick(index, idx, obj) {
      if (this.isTryCourse && obj.idx > 2) {
        this.$message.warning("试用课程仅可使用前三个知识点！");
        return;
      }
      this.knowledgeList.forEach((element, i) => {
        element.children.forEach((ele, j) => {
          if (index === i && idx === j) {
            ele.isChosed = true;
          } else {
            ele.isChosed = false;
          }
        });
        this.$set(this.knowledgeList, i, element);
      });
      console.log("obj===", obj);
      this.knowledgeId = obj.id;
      this.resourcesItem = {}; // 将选择课件为空
      this.getData(obj.id);
    },
    /**
     * 选择班级
     */
    handleClassBtn(item, index) {
      this.classList.forEach((element, idx) => {
        if (idx === index) {
          if (!item.isChosed) {
            this.classId = item.id;
            this.classType = item.classType;
            this.gradeId = item.gradeId;
            this.className = item.className;
            this.schoolCode = item.schoolCode;
            let data = handparams({
              courseId: this.id,
              originTenantId: this.originTenantId,
              originTenantType: this.originTenantType,
              classId: this.classId,
              gradeId: this.gradeId,
              classType: this.classType,
            });
            this.getCourseClassList(data, false);
          } else {
            this.classId = "";
            this.classType = "";
            this.gradeId = "";
            this.className = "";
            this.schoolCode = "";
          }
          element.isChosed = !element.isChosed;
        } else {
          element.isChosed = false;
        }
        this.$set(this.classList, idx, element);
      });
    },
    // 选择课件
    handleResourceClick(item, index) {
      console.log("item=======", item);
      this.resourcesItem = item;
      this.resourcesList.forEach((element, idx) => {
        if (idx === index) {
          element.isChosed = true;
        } else {
          element.isChosed = false;
        }
        this.$set(this.resourcesList, idx, element);
      });
    },
    /**
     * 跳转去授课
     **/
    submitTeachingForm() {
      console.log("resourcesItem", this.resourcesItem);
      if (!this.resourcesItem.resourceId) {
        this.$message.warning(this.$t("el.teaching.chooseCourseWare"));
        return;
      }
      if (this.isPending) {
        return;
      }
      this.isPending = true;
      checkAvailability({ courseTenantInfoId: this.courseTenantInfoId })
        .then((res) => {
          this.$router.push({
            path: "/teaching",
            query: {
              id: this.knowledgeId,
              courseId: this.id,
              originTenantId: this.originTenantId,
              originTenantType: this.originTenantType,
              from:
                (this.defaultFile && this.defaultFile.from) || "courseDetails",
              resourceId: this.resourcesItem.resourceId,
              resourceType: this.defaultFile
                ? this.defaultFile.resourceType
                : "KJ",
              classId: this.classId,
              format: this.resourcesItem.format,
              version: this.resourcesItem.version,
              resourceName: this.resourcesItem.resourceName,
              resourceCategory: this.resourcesItem.category,
              gradeId: this.gradeId,
              classType: this.classType,
              className: this.className,
              schoolCode: this.schoolCode,

              courseTenantInfoId: this.courseTenantInfoId,
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.isPending = false;
          }, 300);
        });
      // .catch((err) => {
      //   this.$message.error("当前非可用时段，不可进行备课/授课");
      // });
    },
    closeTeachingDialog() {
      // this.knowledgeId = null;
      // this.showTeachingDialog = false;
      if (this.isCaching) {
        this.exitVisible = true;
        return;
      }
      Object.assign(this.$data, this.$options.data.call(this));
    },
    // 退出缓存
    handleExitCache() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    /**
     * 获取数据
     * @param {*} knowledgeId 知识点id
     * @param {*} resourceId 资源id
     * @param {*} classId 选中的班级id
     */
    getData(knowledgeId, resourceId, classId) {
      this.dialogLoading = true;
      console.log("this.defaultFile===", this.defaultFile);
      getQueryResourceAndClass(
        this.defaultFile ? this.defaultFile.resourceType : "KJ",
        { knowledgeId }
      )
        .then(({ data }) => {
          // console.log(data);
          // this.classList = Array.isArray(data.classes) ? data.classes : [];
          // if (this.classList.length > 0) {
          //   this.classList[0].isChosed = true;
          //   this.classId = this.classList[0].id;
          //   this.$set(this.classList, 0, this.classList[0]);
          // }
          // 判断课件缓存状态
          // let resourceIdList = JSON.parse(
          //   localStorage.getItem("resourceIdList")
          // );
          // if (Array.isArray(data.resources)) {
          //   data.resources.forEach((element) => {
          //     if (
          //       Object.prototype.toString.call(resourceIdList) ===
          //       "[object Array]"
          //     ) {
          //       if (resourceIdList.indexOf(element.resourceId + "") > -1) {
          //         element.cacheStatus = 4;
          //       } else {
          //         element.cacheStatus = 1;
          //       }
          //     } else {
          //       element.cacheStatus = 1;
          //     }
          //     // 如果有缓存中的任务，保留缓存中
          //     if (
          //       this.cacheTaskList.find(
          //         (item) => ~~item.resourceId === ~~element.resourceId
          //       )
          //     ) {
          //       element.cacheStatus = 2;
          //     }
          //   });

          //   this.resourcesList = data.resources;
          // } else {
          //   this.resourcesList = [];
          // }

          this.resourcesList = data.resources;

          console.log("this.resourcesList=========", this.resourcesList);

          // 默认选中的课件
          if (this.defaultFile && this.defaultFile.resourceId) {
            let index = this.resourcesList.findIndex(
              (item) => ~~item.resourceId === ~~this.defaultFile.resourceId
            );
            this.$nextTick(() => {
              console.log("index=========", index);
              if (index > -1) {
                this.resourcesList[index].isChosed = true;
                this.$set(this.resourcesList, index, this.resourcesList[index]);
                this.resourcesItem = this.resourcesList[index];
              }
            });
          }
          // 异步获取列表之后
          this.$nextTick(() => {
            // 解决滚动条初始化消失的问题
            console.log(" this.$refs.scrollDiv====", this.$refs.scrollDiv);
            this.$refs.scrollDiv.update();
          });
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    // 课程和班级列表
    getCourseClassList(data, isDefaultChose) {
      this.dialogLoading = true;
      return getCourseClassList(data)
        .then((result) => {
          // console.log("result:",result);
          this.courseObj = result.data;
          // 置空 课件列表
          this.knowledgeId = null;
          this.resourcesList = [];
          this.resourcesItem = {}; // 将选择课件为空
          /**
           *  isDefaultChose 为 true： 默认选中第一个班级，调用接口 getCourseClassList 获取 已上 的状态
           *  isDefaultChose 为 false: 更新知识点列表
           */
          if (isDefaultChose) {
            if (result.data && result.data.classVos) {
              this.classList = Array.isArray(result.data.classVos)
                ? result.data.classVos
                : [];
              console.log("this.classList", this.classList);
              if (this.classList.length > 0) {
                // this.classList[0].isChosed = true;
                // this.classId = this.classList[0].id;
                // this.gradeId = this.classList[0].gradeId;
                // this.classType = this.classList[0].classType;
                // this.$set(this.classList, 0, this.classList[0]);
                return this.handleClassBtn(this.classList[0], 0);
              } else {
                // this.dialogLoading = false;
              }
            }
          } else {
            // this.dialogLoading = false;
          }

          this.isTryCourse = result.data.isTry;

          if (result.data && result.data.knowledgeList) {
            console.log("this.defaultFile", this.defaultFile);
            console.log("result.data.knowledgeList", result.data.knowledgeList);

            // 如果有默认 选中的 知识点 和 课件时，默认选中知识点，调用接口，默认选中课件
            if (this.defaultFile && this.defaultFile.knowledgeId) {
              result.data.knowledgeList.forEach((element) => {
                let index = element.children.findIndex(
                  (item) => ~~item.id === ~~this.defaultFile.knowledgeId
                );
                console.log("index", index);
                if (index > -1) {
                  element.children[index].isChosed = true;
                  // element.isRetract = true;
                  // 默认选中 知识点时 调用 获取课件接口
                  this.knowledgeId = element.children[index].id;
                  this.resourcesItem = {}; // 将选择课件为空
                  this.getData(element.children[index].id);
                } else {
                  this.dialogLoading = false;
                }
              });
            } else {
              this.dialogLoading = false;
            }
            let idx = 0;
            result.data.knowledgeList.forEach((element) => {
              element.children.forEach((ele) => {
                ele.idx = idx;
                idx = idx + 1;
              });
            });
            this.knowledgeList = result.data.knowledgeList;
            console.log("this.knowledgeList ===", this.knowledgeList);
          } else {
            this.knowledgeList = [];
            this.dialogLoading = false;
          }
          // 异步获取列表之后
          this.$nextTick(() => {
            // 解决滚动条初始化消失的问题

            this.$refs.scrollDivLeft.update();
          });
        })
        .catch(() => {
          this.knowledgeList = [];
          this.classList = [];
          this.dialogLoading = false;
        });
    },

    // 课程详情 没用到？
    getCourseDetail(data) {
      this.dialogLoading = true;
      return getCourseDetail(data)
        .then((result) => {
          // console.log("result:",result);
          this.courseObj = result.data;
          if (result.data && result.data.knowledgeList) {
            this.knowledgeList = result.data.knowledgeList;
            this.allowed = result.data.allowed || 0;
          } else {
            this.knowledgeList = [];
          }
          this.dialogLoading = false;
        })
        .catch(() => {
          this.knowledgeList = [];
          this.dialogLoading = false;
        });
    },
    getWpsType(format) {
      if (["mp4", "MP4", "mov", "MOV"].indexOf(format) > -1) {
        return "video";
      } else if (
        [
          "mp3",
          "MP3",
          "wav",
          "WAV",
          "flac",
          "FLAC",
          "aac",
          "AAC",
          "m4a",
          "M4A",
        ].indexOf(format) > -1
      ) {
        return "audio";
      } else if (["doc", "docx", "DOC", "DOCX"].indexOf(format) > -1) {
        return "doc";
      } else if (["ppt", "pptx", "PPT", "PPTX"].indexOf(format) > -1) {
        return "ppt";
      } else if (["pdf", "PDF"].indexOf(format) > -1) {
        return "pdf";
      } else if (["xls", "xlsx", "xlsx", "XLSX"].indexOf(format) > -1) {
        return "xls";
      } else if (
        ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"].indexOf(format) > -1
      ) {
        return "image";
      } else {
        return "otherType";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.teaching-dialog {
  /deep/ .el-dialog {
    width: 950px !important;
    height: 560px;
    background: #ffffff;
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    padding: 19px 30px 10px !important;
    text-align: left;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #131313;
  }
  /deep/ .el-dialog__body {
    padding: 13px 0 0 32px;
  }
  /deep/ .btn-content {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: 0;
    margin-bottom: 18px;
    text-align: center !important;
    .el-button--small {
      padding: 9px 20px;
      font-size: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 15px;
    }
    .el-button + .el-button,
    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
      margin-left: 32px;
    }
  }
  // 退出缓存二次弹框样式
  .el-dialog_title {
    font-size: 20px;
    padding-top: 20px;
    padding-left: 20px;
  }
  .el-dialog__content {
    font-size: 19px;
    color: #646c95;
    line-height: 37px;
    letter-spacing: 1px;
    text-align: center;
    a {
      color: @themeBlue;
    }
  }

  .custom-content {
    height: 422px;
    .left-content {
      width: 443px;
      height: 100%;
      // overflow-y: auto;
      box-sizing: border-box;

      /deep/.el-scrollbar {
        overflow: hidden;
        position: relative;
        /* margin-right: -15px; */
        height: 100%;
      }
      /deep/ .el-scrollbar__wrap {
        overflow: auto;
        height: 100%;
      }
      .no-img {
        width: 200px;
        height: 91px;
      }
      .no-text {
        width: fit-content;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000;
        opacity: 0.45;
        margin-left: auto;
        margin-right: auto;
        margin-top: 23px;
      }
      .btn-class-chose {
        width: fit-content;
        height: 30px;
        padding-left: 19px;
        padding-right: 19px;
        background: #5a53f5;
        box-shadow: 0px 4px 5px 0px rgba(90, 83, 245, 0.19);
        border-radius: 15px;

        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }
      .btn-class-unchose {
        width: fit-content;
        height: 30px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: content-box;
        padding-left: 19px;
        padding-right: 19px;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 31px;
        opacity: 0.45;
        cursor: pointer;
      }
      // 知识点样式 start
      .details-chapter {
        &-header {
          margin-bottom: 14px;
          padding-left: 9px;
          cursor: pointer;
          .u-line {
            width: 100%;
            height: 5px;
            background: #cde2fc;
            border-radius: 3px;
          }
        }
        &-icon {
          width: 9px;
          height: 14px;
          margin-left: 5px;
        }
        &-icon2 {
          width: 14px;
          height: 9px;
          margin-top: 5px;
        }
        &-title {
          position: relative;
          max-width: 240px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #131313;
          margin-left: 12px;
        }
      }
      .details-section:hover {
        cursor: pointer;
        color: @themeBlue;
      }
      .details-section-chose {
        cursor: pointer;
        color: @themeBlue;
        box-sizing: border-box;
        padding-left: 74px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-bottom: 13px;
        span {
          max-width: 236px;
        }
      }

      .details-section {
        box-sizing: border-box;
        padding-left: 74px;

        font-size: 14px;

        margin-bottom: 13px;

        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        span {
          max-width: 236px;
        }

        &-limit {
          color: #c9c9c9;
        }

        &-content {
          width: fit-content;
          margin-right: 14px;
          margin-left: auto;
        }

        // {{ $t('el.classroomWps.teacherInstructions')}}
        &-instructions {
          padding: 3px 12px;
          height: fit-content;
          background: rgba(90, 83, 245, 0.15);
          border-radius: 12px;

          font-size: 12px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #5a53f5;
          margin-right: 12px;
          // margin-left: auto;
        }
        &-lock {
          width: 16px;
          height: 17px;
          margin-right: 12px;
          // margin-left: auto;
        }

        &-btn {
          margin-left: auto;
          // margin-right: 14px;
          &-bk {
            width: fit-content;
            height: 24px;
            background: #f1f4fe;
            border-radius: 12px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5a53f5;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
            margin-right: 20px;
          }
          &-sk {
            width: fit-content;
            height: 24px;
            background: #f4f5f8;
            border-radius: 12px;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 24px;
            color: #999999;

            text-align: center;
            cursor: pointer;
          }
        }
      }
      // .disabled-class {
      //   color: rgba(212, 212, 212, 1) !important;

      //   font-size: 12px;
      //   font-family: PingFang SC;
      //   color: #5a53f5;
      //   line-height: 24px;
      //   cursor: inherit;
      // }
      .dash-line {
        margin-left: 42px;
        margin-right: 42px;
        margin-bottom: 14px;
        height: 1px;
        // scale: 0.5;
        background: #ededed;
      }

      // 知识点样式 end
    }
    .right-content {
      width: 475px;
      height: 100%;
      padding-left: 16px;
      padding-right: 6px;
      box-sizing: border-box;
      background: white;
      .course-content {
        overflow-y: auto;
        .resource-item:hover {
          background: #5a53f5;
        }
        .resource-item:hover .resource-item-mine {
          // border: 1px solid #ffffff;
          color: #ffffff;
        }
        .resource-item:hover .resource-item-name {
          color: #ffffff;
        }
        .resource-item {
          position: relative;
          width: 441px;
          height: 52px;
          background: #f1f4fe;
          border-radius: 10px;
          margin-right: 16px;
          margin-bottom: 16px;
          padding-left: 12px;
          padding-top: 14px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          box-sizing: border-box;
          cursor: pointer;
          &-chosed {
            position: relative;
            width: 441px;
            height: 52px;
            background: #5a53f5;
            border-radius: 10px;
            margin-right: 16px;
            margin-bottom: 16px;
            padding-left: 12px;
            padding-top: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            box-sizing: border-box;
            cursor: pointer;
            // .resource-item-mine {
            //   border: 1px solid #ffffff;
            //   color: #ffffff;
            // }
            .resource-item-name {
              color: #ffffff;
            }
          }
          &-img {
            width: 24px;
            height: 24px;
          }
          &-mine {
            position: absolute;
            top: 0;
            right: 0;
            width: 35px;
            height: 35px;
          }

          &-line {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 4px;
            height: 28px;
            background: #5a53f5;
            border-radius: 2px;
          }
          &-name {
            color: #333333;
            width: 280px;
            // margin-top: 18px;
            // margin-right: 22px;
            margin-left: 6px;
            text-align: left;
          }
          &-cache {
            width: 60px;
            height: 20px;
            box-sizing: border-box;
            background: #fcead1;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #eea642;
            line-height: 20px;
            margin-right: 41px;
            margin-left: auto;
            border-radius: 20px;
            cursor: pointer;
          }
          &-caching {
            width: 60px;
            height: 20px;
            box-sizing: border-box;
            background: #f7e8ea;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #cf6779;
            line-height: 20px;
            margin-right: 41px;
            margin-left: auto;
            border-radius: 20px;
            cursor: pointer;
          }
          &-cached {
            width: 60px;
            height: 20px;
            box-sizing: border-box;
            background: #ffffff;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 20px;
            margin-right: 41px;
            margin-left: auto;
            border-radius: 20px;
            cursor: default;
          }
        }
      }
      .empty-content {
        text-align: center;
        .resource-empty {
          margin-top: 130px;
          width: 200px;
          height: 91px;
          margin-left: auto;
          margin-right: auto;
        }
        &-text {
          width: fit-content;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          opacity: 0.45;
          margin-left: auto;
          margin-right: auto;
          margin-top: 23px;
        }
      }
    }
    &-line {
      width: 2px;
      height: 100%;
      padding-top: 2px;
      padding-bottom: 2px;
      transform: scaleX(0.5);
      background: #ededed;
    }
    &-title {
      text-align: left;

      height: 13px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #7aabf3;
    }
  }
}
</style>
