<template>
  <!-- 缓存wps -->
  <div
    v-if="dialogVisiable && fileType === 'wpsFile'"
    class="custom-content-wps t-c"
  >
    <iframe
      id="iframeWps"
      :src="iframeSrc"
      style="height: 100%"
      width="100%"
      scrolling="no"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { handparams } from "@/utils";
export default {
  data() {
    return {
      dialogVisiable: false,
      iframeSrc: "",
      courseId: "",
    };
  },
  directives: {},
  components: {},
  props: {
    showPreviewDialog: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: () => {},
    },
    fileType: {
      type: String,
      default: "",
    },
  },
  watch: {
    // 各类型弹窗初始化配置
    showPreviewDialog(newVal, oldVal) {
      this.dialogVisiable = newVal;
      console.log("this.dialogVisiable==", this.dialogVisiable);
      if (newVal) {
        if (!this.courseId) {
          this.courseId = this.file.courseId;
          console.log("this.courseId====", this.courseId);
        }
        if (this.fileType === "wpsFile") {
          this.courseware = this.file;

          console.log("this.file.format===", this.file.format);
          this.showPPTpages =
            ["ppt", "pptx", "PPT", "PPTX"].indexOf(this.file.format) > -1;

          this.iframeSrc = `${process.env.VUE_APP_BASE_URL}PreviewWpsDialog?showPPTpages=true&format=${this.file.format}&resourceId=${this.file.resourceId}&version=${this.file.version}&resourceType=${this.file.resourceType}&courseId=${this.courseId}&isCache=true`;

          console.log("this.iframeSrc===", this.iframeSrc);
        }
      }
    },
  },
  created() {
    console.log("this.file===", this.file);
    this.courseId = this.$route.query.courseId;
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style lang="less" scoped>
.custom-content-wps {
  position: fixed;
  width: 0;
  height: 0;
}
</style>
